import React, { useEffect, useState } from "react";
import axios from "axios";

const Sidebar = () => {
  const [notifications, setNotifications] = useState([]);
  const [id, setId] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    // get token details
    const tk = sessionStorage.getItem("token");
    setToken(tk);
    axios
      .get(`${process.env.REACT_APP_Base_url}/company/view_company_details/`, {
        headers: {
          "X-COMPANY-USER-AUTH": token,
        },
      })
      .then((res) => {
        console.log(res.data);
        setId(res.data.company_details.company_uuid);
      });

    if (id) {
      axios
        .post(
          `${process.env.REACT_APP_Base_url}/contact-company/list_company_enquiries/`,
          {
            company_id: id,
          },
          {
            headers: {
              "X-COMPANY-USER-AUTH": tk,
            },
          }
        )
        .then((resp) => {
          console.log(resp.data);
          setNotifications(resp.data.company_messages);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id, token]);

  return (
    <>
      <nav>
        <ul>
          {notifications && (
            <li>
              <a href="/Task">
                <img
                  src="https://img.icons8.com/ios/34/000000/task.png"
                  alt="task"
                  className="px-3"
                />
                Task
                <span className="badge bg-red badge-corner">
                  {notifications.length}
                </span>
              </a>
            </li>
          )}
          {/* <li>
            <a href="/PendingDelivery">
              {" "}
              <img
                src="https://img.icons8.com/dotty/34/000000/delivery.png"
                alt="pending Delivery"
                className="px-2"
              />{" "}
              Pending Delivery{" "}
              <span className="badge bg-red badge-corner">{0}</span>
            </a>
          </li> */}
        </ul>
      </nav>
    </>
  );
};

export default Sidebar;
