import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Password } from "primereact/password";
import { Divider } from "primereact/divider";

const Register = () => {
  const navigate = useNavigate();
  const [corporate_name, setCorporate_name] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [industry_uuid, setIndustry] = useState("");
  const [account_type, setAccount] = useState("");
  const [policy, setPolicy] = useState(false);
  const [country_uuid, setCountryId] = useState(null);
  const [accountList, setAccountList] = useState(null);
  const [industryList, setIndustryList] = useState(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_Base_url}/setup/get_countries/`)
      .then((res) => {
        console.log(res.data.country[0].country_id);
        const countryid = res.data.country[0].country_id;
        setCountryId(countryid);
      })
      .catch(console.log);

    axios
      .get(`${process.env.REACT_APP_Base_url}/setup/get_industries/`)
      .then((res2) => {
        console.log(res2.data);
        setIndustryList(res2.data.industries);
      })
      .catch(console.log);

    axios
      .get(`${process.env.REACT_APP_Base_url}/setup/get_account_types/`)
      .then((res3) => {
        console.log(res3.data);
        setAccountList(res3.data.account_types);
      })
      .catch(console.log);
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords don't match");
    } else {
      await axios
        .post(`${process.env.REACT_APP_Base_url}/company/create_company/`, {
          name: corporate_name,
          address,
          phone: contact,
          industry_uuid,
          country_uuid,
          password,
          email,
          account_type,
          logo: "",
          website: "",
          momNumber: "",
          momoName: "",
          googleLocation: "",
        })
        .then((response) => {
          console.log(response);
          if (response.data.status === 201) {
            console.log("Redirect successful");
            alert(response.data.message);
            navigate("/");
          } else {
            alert(response.data.message);
          }
        });
    }
  };

  const passheader = <h6>Pick a password</h6>;
  const passfooter = (
    <React.Fragment>
      <Divider />
      <p className="p-mt-2">Suggestions</p>
      <ul className="p-pl-2 p-ml-2 p-mt-0" style={{ lineHeight: "1.5" }}>
        <li>At least one lowercase</li>
        <li>At least one uppercase</li>
        <li>At least one numeric</li>
        <li>Minimum 8 characters</li>
      </ul>
    </React.Fragment>
  );

  return (
    <div className="page login-page">
      <div className="container d-flex align-items-center">
        <div className="form-holder has-shadow">
          <div className="row">
            {/* Logo & Information Panel*/}
            <div className="col-lg-6">
              <div className="info d-flex align-items-center">
                <div className="content">
                  <div className="logo">
                    <h1>RosmallOnline</h1>
                  </div>
                  <p>Register with us and feel the power</p>
                </div>
              </div>
            </div>
            {/* Form Panel    */}
            <div className="col-lg-6 bg-white">
              <div className="form d-flex align-items-center">
                <div className="content">
                  <form className="form-group" onSubmit={submitHandler}>
                    <div className="form-group">
                      <input
                        type="text"
                        required="required"
                        className="input-material"
                        placeholder="Corporate Name"
                        onChange={(e) => setCorporate_name(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <input
                        type="text"
                        required="required"
                        className="input-material"
                        placeholder="Location"
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <input
                        type="email"
                        required="required"
                        className="input-material"
                        placeholder="Email Address"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <input
                        type="tel"
                        required="required"
                        className="input-material"
                        placeholder="Contact"
                        maxLength={10}
                        minLength={10}
                        onChange={(e) => setContact(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <Password
                        className="input-material"
                        required="required"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        toggleMask
                        header={passheader}
                        footer={passfooter}
                      />
                    </div>

                    <div className="form-group">
                      <Password
                        className="input-material"
                        required="required"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder=" Confirm Password"
                        toggleMask
                        header={passheader}
                        footer={passfooter}
                      />
                    </div>

                    {/* CHECK BOX */}

                    {industryList && (
                      <div className="form-group">
                        <label htmlFor="industry" className="label-material">
                          Industry{" "}
                        </label>
                        <div className="col-sm-9">
                          <select
                            name="industry"
                            className="form-control mb-3"
                            required
                            style={{ width: "auto" }}
                            onChange={(e) => setIndustry(e.target.value)}>
                            <option value="">Select an industry</option>
                            {industryList.map((item, key) => {
                              return (
                                <option value={item.industry_id} key={key}>
                                  {item.industry_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    )}
                    <hr />

                    {accountList && (
                      <div className="form-group">
                        <label htmlFor="industry" className="label-material">
                          Account Type{" "}
                        </label>
                        <div className="col-sm-9">
                          <select
                            name="account"
                            className="form-control mb-3"
                            required
                            style={{ width: "auto" }}
                            onChange={(e) => setAccount(e.target.value)}>
                            <option value="">Select an Account</option>
                            {accountList.map((item, key) => {
                              return (
                                <option value={item.account_type_id} key={key}>
                                  {item.account_type}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    )}
                    <hr />

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckDefault"
                        required
                        onChange={(e) => setPolicy(!policy)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault">
                        <Link to="/terms_&_conditions" className="">
                          Agree to terms and conditions
                        </Link>
                      </label>
                    </div>
                    <br />

                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        Register
                      </button>
                    </div>

                    {/* {policy && (
                      <div className="form-group">
                        <button type="submit" className="btn btn-primary">
                          Register
                        </button>
                      </div>
                    )} */}
                  </form>
                  <small>Already have an account? </small>
                  <a href="/" className="signup">
                    Login
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyrights text-center">
        <p>
          Owned by{" "}
          <a
            href="https://rosmallonline.com"
            target="_blank"
            rel="noreferrer"
            className="external">
            RosmallOnline
          </a>
        </p>
      </div>
    </div>
  );
};

export default Register;
