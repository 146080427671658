import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../Views/Footer";
import Header from "../../Views/Header";
import axios from "axios";
// import Sidebar from "../../Pages/Sidebar";
import { ToastContainer, toast } from "react-toastify";

const DraftedProduct = () => {
  const [token, setToken] = useState(null);
  const [draftedProduct, setDraftedProduct] = useState([]);
  const [accountType, setAccountType] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setToken(sessionStorage.getItem("token"));
    setAccountType(sessionStorage.getItem("accountType"));

    if (token) {
      axios
        .get(`${process.env.REACT_APP_Base_url}/product/list_pending_post/`, {
          headers: {
            "X-COMPANY-USER-AUTH": token,
          },
        })
        .then((res) => {
          console.log(res.data);
          setDraftedProduct(res.data.pending_post);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [token]);

  const Activate = (id) => {
    console.log(id);
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/product/request_activation/`,
        {
          record_id: id,
          record_name: "product",
        },
        {
          headers: {
            "X-COMPANY-USER-AUTH": token,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        // if (accountType === "Business Standard") {
        //   alert("Please proceed to  make payment for item to be Activated");
        //   navigate("/Payment", { state: { id: id } });
        // } else {
        //   console.log(response.data);
        //   if (response.status === 200) {
        //     toast.success(response.data.message);
        //     setTimeout(() => {
        //       window.location.reload(true);
        //     }, 5000);
        //   }
        // }

        if (response.data.status === 200) {
          toast.success(response.data.message);
          window.location.replace("/drafted_product");
        } else {
          toast.success(response.data.message);
          window.location.replace("/drafted_product");
        }
      })
      .catch((error) => {
        toast.error(error);
        console.log(error);
      });
  };

  const Delete = (id) => {
    console.log(id);
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/product/delete_post/`,
        {
          product_ids: [id],
        },
        {
          headers: {
            "X-COMPANY-USER-AUTH": token,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          toast.error(response.data.message);
          setTimeout(() => {
            window.location.reload(true);
          }, 3000);
        }
      })
      .catch((error) => {
        toast.error(error);
        console.log(error);
      });
  };

  return (
    <>
      <ToastContainer />
      <div className="page">
        {/* Main Navbar*/}
        <Header />
        <div className="page-content d-flex align-items-stretch">
          {/* Side Navbar */}
          <nav className="side-navbar">
            {/* Sidebar Header*/}

            {/* Sidebar Navigation Menus*/}
            <span className="heading py-3">Main</span>
            <ul className="list-unstyled">
              <li>
                <a href="/dashboard">
                  {" "}
                  <img
                    src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/34/000000/external-dashboard-agile-flaticons-lineal-color-flat-icons.png"
                    alt="Dashboard"
                    className="px-2"
                  />{" "}
                  Dashboard{" "}
                </a>
              </li>
              <li>
                <a href="/profile">
                  {" "}
                  <img
                    src="https://img.icons8.com/ios/34/000000/user-male-circle.png"
                    alt="Profile"
                    className="px-2"
                  />{" "}
                  Profile{" "}
                </a>
              </li>
              {/* <Sidebar /> */}
              <li>
                <a href="/notifications">
                  {" "}
                  <img
                    src="https://img.icons8.com/ios/34/000000/apple-mail.png"
                    alt="Notification"
                    className="px-2"
                  />{" "}
                  Notification{" "}
                </a>
              </li>
              <li>
                <a href="/update_profile">
                  {" "}
                  <img
                    src="https://img.icons8.com/ios/34/000000/edit-user-male.png"
                    alt="update"
                    className="px-2"
                  />{" "}
                  Update Details{" "}
                </a>
              </li>
              <li>
                <a href="/account_details">
                  {" "}
                  <img
                    src="https://img.icons8.com/dotty/34/000000/money-bag-euro.png"
                    alt="Payment Details"
                    className="px-2"
                  />{" "}
                  Payment Details{" "}
                </a>
              </li>
              <li>
                <a href="/change_password">
                  {" "}
                  <img
                    src="https://img.icons8.com/ios/34/000000/settings--v2.png"
                    alt="Settings"
                    className="px-2"
                  />{" "}
                  Settings{" "}
                </a>
              </li>
            </ul>
          </nav>
          <div className="content-inner">
            {/* Page Header*/}
            <header className="page-header">
              <div className="container-fluid">
                <h2 className="no-margin-bottom">DRAFTED PRODUCT DASHBOARD</h2>
              </div>
            </header>
            {/* Updates Section     */}
            <section className="updates no-padding-top">
              <div className="container-fluid">
                <div className="row">
                  {/* Daily Feeds */}
                  <div className="col-lg-12 pt-5">
                    <div className="daily-feeds card">
                      <div className="card-close">
                        <div className="dropdown">
                          <button
                            type="button"
                            id="closeCard7"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            className="dropdown-toggle">
                            <i className="fa fa-ellipsis-v" />
                          </button>
                          <div
                            aria-labelledby="closeCard7"
                            className="dropdown-menu dropdown-menu-right has-shadow">
                            <a href="#/" className="dropdown-item remove">
                              {" "}
                              <i className="fa fa-times" />
                              Close
                            </a>
                            <a href="#/" className="dropdown-item edit">
                              {" "}
                              <i className="fa fa-gear" />
                              Edit
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="card-header">
                        <h3 className="h4">DRAFTED PRODUCT LIST</h3>
                      </div>
                      <div className="card-body no-padding">
                        {/* Item*/}
                        {draftedProduct &&
                        draftedProduct.length !== 0 &&
                        draftedProduct.length !== undefined ? (
                          draftedProduct.map((product) => {
                            return (
                              <div className="item" key={product.product_uuid}>
                                <div className="feed d-flex justify-content-between">
                                  <div className="feed-body d-flex justify-content-between">
                                    <a href="#/" className="feed-profile">
                                      <img
                                        src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                                        alt="person"
                                        className="img-fluid rounded-circle"
                                      />
                                    </a>
                                    <div className="content">
                                      <h5>{product.product_name}</h5>
                                      <span>{product.product_type}</span>
                                      <div className="CTAs">
                                        <button
                                          onClick={() =>
                                            Activate(product.product_uuid)
                                          }
                                          className="btn btn-sm btn-success">
                                          Request Activation
                                        </button>
                                        <Link
                                          to="/drafted_product_edit"
                                          state={{ id: product.product_uuid }}
                                          className="btn btn-xs btn-warning m-1 p-1">
                                          <i className="fa fa-pencil-square-o">
                                            {" "}
                                          </i>
                                          Edit
                                        </Link>
                                        {/* <Link to={{ pathname: `/Payment`, data: {id :product.product_uuid } }} className="btn btn-xs btn-warning m-1 p-1"><i className="fa fa-pencil-square-o"> </i>Payment</Link> */}
                                        <button
                                          onClick={() =>
                                            Delete(product.product_uuid)
                                          }
                                          className="btn btn-sm btn-danger">
                                          <i className="fa fa-trash-o"> </i>
                                          <span
                                            className="px-2"
                                            style={{ color: "#fff" }}>
                                            Delete
                                          </span>{" "}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="date text-right">
                                    <small>{product.date_created}</small>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div className="text-center pt-4">
                            <p>No records found</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Page Footer*/}
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default DraftedProduct;
