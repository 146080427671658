import React, { useEffect, useState } from "react";
import Footer from "../Views/Footer";
import Header from "../Views/Header";
import axios from "axios";
import Sidebar from "../Views/Sidebar";
import { useLocation } from "react-router-dom";

const Payment = () => {
  const location = useLocation();
  const [token, setToken] = useState(null);
  const [state, setState] = useState(null);
  const [id, setId] = useState(null);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);
    if (location.data) {
      setId(location.data.id);
    }
  }, [token, location.data]);

  useEffect(() => {
    if (id) {
      axios
        .post(
          `${process.env.REACT_APP_Base_url}/product/product_details/`,
          {
            post_id: id,
          },
          {
            headers: {
              "x-user-pass-auth":
                "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          setState(response.data.post_details);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [id, token]);

  const handlePayment = async (e) => {
    e.preventDefault();
    await axios
      .post(
        "https://app.mybusinesspay.com/payment/v1.0/initialize",
        {
          metadata: {
            order_id: 98876,
            product_name: state.product_name,
            product_description: state.description,
          },
          callback: `${process.env.REACT_APP_Callback_url}`,
          post_url: `${process.env.REACT_APP_Base_url}/payment/cart_payment_callback/`,
          pub_key: process.env.REACT_APP_Pub_key,
          first_name: state.company.company_name,
          currency: "GHS",
          amount: state.amount_to_pay,
          phone_number: state.company.phone_number,
        },
        {
          headers: {
            Authorization: process.env.REACT_APP_Payment_key,
          },
        }
      )
      .then((response) => {
        console.log(response);
        window.location.replace(response.data.url);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="page">
      {/* Main Navbar*/}
      <Header />

      <div className="page-content d-flex align-items-stretch">
        {/* Side Navbar */}
        <nav className="side-navbar">
          {/* Sidebar Header*/}

          {/* Sidebar Navidation Menus*/}
          <span className="heading py-3">Main</span>
          <ul className="list-unstyled">
            <li>
              <a href="/dashboard">
                {" "}
                <img
                  src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/34/000000/external-dashboard-agile-flaticons-lineal-color-flat-icons.png"
                  alt="Dashboard"
                  className="px-2"
                />{" "}
                Dashboard{" "}
              </a>
            </li>
            <li>
              <a href="/profile">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/user-male-circle.png"
                  alt="Profile"
                  className="px-2"
                />{" "}
                Profile{" "}
              </a>
            </li>
            <Sidebar />
            <li>
              <a href="/notifications">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/apple-mail.png"
                  alt="Notification"
                  className="px-2"
                />{" "}
                Notification{" "}
              </a>
            </li>
            <li>
              <a href="/update_profile">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/edit-user-male.png"
                  alt="update"
                  className="px-2"
                />{" "}
                Update Details{" "}
              </a>
            </li>
            <li>
              <a href="/account_details">
                {" "}
                <img
                  src="https://img.icons8.com/dotty/34/000000/money-bag-euro.png"
                  alt="Payment Details"
                  className="px-2"
                />{" "}
                Payment Details{" "}
              </a>
            </li>
            <li>
              <a href="/change_password">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/settings--v2.png"
                  alt="Settings"
                  className="px-2"
                />{" "}
                Settings{" "}
              </a>
            </li>
          </ul>
        </nav>
        <div className="content-inner">
          {/* Forms Section*/}
          <section className="forms">
            <div className="container-fluid">
              <div className="row">
                {/* Form Elements */}
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-close">
                      <div className="dropdown">
                        <button
                          type="button"
                          id="closeCard5"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="dropdown-toggle">
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        <div
                          aria-labelledby="closeCard5"
                          className="dropdown-menu dropdown-menu-right has-shadow">
                          <a href="#/" className="dropdown-item remove">
                            {" "}
                            <i className="fa fa-times" />
                            Close
                          </a>
                          <a href="#/" className="dropdown-item edit">
                            {" "}
                            <i className="fa fa-gear" />
                            Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card-header d-flex align-items-center">
                      <h3 className="h4"> Payment Details </h3>
                    </div>
                    {state && (
                      <div className="card-body">
                        <div className="line"> </div>
                        <div className="row">
                          <div className="col-lg-8"></div>
                          {/* ORDER SUMMARY*/}
                          <div className="col-12">
                            <div className="card border-0 rounded-0 p-lg-4 bg-light">
                              {state && (
                                <div className="card-body">
                                  <h5 className="text-uppercase mb-4">
                                    Your order
                                  </h5>
                                  <ul className="list-unstyled mb-0">
                                    <li className="d-flex align-items-center justify-content-between">
                                      <strong className="small font-weight-bold">
                                        Product name
                                      </strong>
                                      <span className="text-muted small">
                                        {state.product_name}
                                      </span>
                                    </li>
                                    <li className="border-bottom my-2" />
                                    <li className="d-flex align-items-center justify-content-between">
                                      <strong className="small font-weight-bold">
                                        Starting date of Ad
                                      </strong>
                                      <span className="text-muted small">
                                        {state.starting_date}
                                      </span>
                                    </li>
                                    <li className="border-bottom my-2" />
                                    <li className="d-flex align-items-center justify-content-between">
                                      <strong className="small font-weight-bold">
                                        Number of days for running Ad
                                      </strong>
                                      <span className="text-muted small">
                                        {state.days}
                                      </span>
                                    </li>
                                    <li className="border-bottom my-2" />
                                    {/* <li className="d-flex align-items-center justify-content-between"><strong className="text-uppercase small font-weight-bold">Total Cost</strong><span>GH₵ <span>{multiply()}</span></span></li> */}
                                    <li className="d-flex align-items-center justify-content-between">
                                      <strong className="text-uppercase small font-weight-bold">
                                        Total Cost
                                      </strong>
                                      <span>
                                        GH₵ <span>{state.amount_to_pay}</span>
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="line" />
                          <div className="form-group row justify-content-center">
                            <div className="col-12 offset-sm-3">
                              <button
                                type="submit"
                                onClick={handlePayment}
                                className="btn btn-primary">
                                Make Payment
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Page Footer*/}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Payment;
