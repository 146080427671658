import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import Notification from "./Components/Notifications/Notification";
import NotificationDetails from "./Components/Notifications/NotificationDetails";
import ActiveBoost from "./Components/Ads Management/ActiveBoost";
import ActiveProducts from "./Components/Ads Management/Products Management/ActiveProducts";
import DraftedProduct from "./Components/Ads Management/Products Management/DraftedProduct";
import ProductPost from "./Components/Ads Management/Products Management/ProductPost";
import ActivationPage from "./Pages/Activation Page/ActivationPage";
import RetiredProducts from "./Components/Ads Management/Products Management/RetiredProducts";
import ServicesPost from "./Components/Ads Management/Services Management/ServicesPost";
import Payment from "./Components/Payment/Payment";
import ErrorPage from "./Pages/404/404";
import DraftedServices from "./Components/Ads Management/Services Management/DraftedServices";
import ActiveServices from "./Components/Ads Management/Services Management/ActiveServices";
import RetiredServices from "./Components/Ads Management/Services Management/RetiredServices";
import DraftedProductEdit from "./Components/Ads Management/Products Management/DraftedProductEdit";
import DraftedServiceEdit from "./Components/Ads Management/Services Management/DraftedServiceEdit";
import PaymentServices from "./Components/Payment/PaymentServices";
import TermsAndConditions from "./Components/Terms/TermsAndConditions";
import Task from "./Components/Task/Task";
import TaskDetails from "./Components/Task/TaskDetails";
import PendingDelivery from "./Components/Pending Delivery/PendingDelivery";
import ProtectedRoute from "./ProtectedRoute";
import Dashboard from "./Pages/Dashboard/Dashboard";
import AccountPage from "./Pages/Account Page/AccountPage";
import ProfilePage from "./Pages/Profile Page/ProfilePage";
import RegistrationPage from "./Pages/Registration Page/RegistrationPage";
import UpdateProfilePage from "./Pages/Profile Page/UpdateProfilePage";
import ChangePasswordPage from "./Pages/Settings Page/ChangePasswordPage";
import ForgotPasswordPage from "./Pages/Settings Page/ForgotPasswordPage";
import ResetPasswordPage from "./Pages/Settings Page/ResetPasswordPage";
import LoginPage from "./Pages/Login Page/LoginPage";
import ConfirmationPaymentDetails from "./Components/Task/ConfirmationPaymentDetails";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/register" element={<RegistrationPage />} />
        <Route path="/activation" element={<ActivationPage />} />
        <Route path="/forgot_password" element={<ForgotPasswordPage />} />
        <Route path="/reset_password" element={<ResetPasswordPage />} />
        <Route path="/terms_&_conditions" element={<TermsAndConditions />} />

        <Route element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/notifications" element={<Notification />} />
          <Route
            path="/notification_details"
            element={<NotificationDetails />}
          />
          <Route path="/task" element={<Task />} />
          <Route path="/task_details" element={<TaskDetails />} />
          <Route
            path="/confirmation_payment_details"
            element={<ConfirmationPaymentDetails />}
          />

          <Route path="/pending_delivery" element={<PendingDelivery />} />
          <Route path="/update_profile" element={<UpdateProfilePage />} />
          <Route path="/change_password" element={<ChangePasswordPage />} />
          <Route path="/product_post" element={<ProductPost />} />
          <Route path="/drafted_product" element={<DraftedProduct />} />
          <Route path="/active_products" element={<ActiveProducts />} />
          <Route path="/retired_products" element={<RetiredProducts />} />
          <Route
            path="/drafted_product_edit"
            element={<DraftedProductEdit />}
          />

          <Route path="/services_post" element={<ServicesPost />} />
          <Route path="/drafted_services" element={<DraftedServices />} />
          <Route path="/active_services" element={<ActiveServices />} />
          <Route path="/retired_services" element={<RetiredServices />} />
          <Route
            path="/drafted_service_edit"
            element={<DraftedServiceEdit />}
          />

          <Route path="/active_boost" element={<ActiveBoost />} />

          <Route path="/payment" element={<Payment />} />
          <Route path="/account_details" element={<AccountPage />} />
          <Route path="/payment_services" element={<PaymentServices />} />
        </Route>

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}

export default App;
