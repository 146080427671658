import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "../Views/Footer";
import Header from "../Views/Header";
import RecentActivities from "./RecentActivities";
import Analytics from "./Analytics";
import Graph from "./Graph";

const Home = () => {
  return (
    <div className="page">
      <Header />

      <div className="page-content d-flex align-items-stretch">
        <nav className="side-navbar">
          {/* Sidebar Navigation Menus*/}
          <span className="heading">Main</span>
          <ul className="list-unstyled">
            <li className="active">
              <a href="/dashboard">
                {" "}
                <img
                  src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/34/000000/external-dashboard-agile-flaticons-lineal-color-flat-icons.png"
                  alt="Dashboard"
                  className="px-2"
                />{" "}
                Dashboard{" "}
              </a>
            </li>
            <li>
              <a href="/profile">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/user-male-circle.png"
                  alt="Profile"
                  className="px-2"
                />{" "}
                Profile{" "}
              </a>
            </li>
            {/* <Sidebar /> */}
            <li>
              <a href="/notifications">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/apple-mail.png"
                  alt="Notification"
                  className="px-2"
                />{" "}
                Notification{" "}
              </a>
            </li>
            <li>
              <a href="/update_profile">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/edit-user-male.png"
                  alt="update"
                  className="px-2"
                />{" "}
                Update Details{" "}
              </a>
            </li>
            <li>
              <a href="/account_details">
                {" "}
                <img
                  src="https://img.icons8.com/dotty/34/000000/money-bag-euro.png"
                  alt="Payment Details"
                  className="px-2"
                />{" "}
                Payment Details{" "}
              </a>
            </li>
            <li>
              <a href="/change_password">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/settings--v2.png"
                  alt="Settings"
                  className="px-2"
                />{" "}
                Settings{" "}
              </a>
            </li>
          </ul>
        </nav>

        <div className="content-inner">
          <header className="page-header">
            <div className="container-fluid">
              <h2 className="no-margin-bottom">Dashboard</h2>
            </div>
          </header>

          <div className="breadcrumb-holder container-fluid">
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <NavLink to="/dashboard">Home</NavLink>
              </li>
            </ul>
          </div>

          <Analytics />

          <Graph />

          <RecentActivities />

          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Home;
