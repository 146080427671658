import React from "react";
import Activation from "../../Components/Activation/Activation";

const ActivationPage = () => {
  return (
    <>
      <Activation />
    </>
  );
};

export default ActivationPage;
