import React from "react";
import AccountDetails from "../../Components/Account Details/AccountDetails";

const AccountPage = () => {
  return (
    <>
      <AccountDetails />
    </>
  );
};

export default AccountPage;
