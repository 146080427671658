import React, { useEffect, useState } from "react";
import axios from "axios";

const Analytics = () => {
  const [state, setState] = useState({
    dashBoardDetails: null,
  });

  useEffect(() => {
    const tk = sessionStorage.getItem("token");

    if (tk) {
      axios
        .get(`${process.env.REACT_APP_Base_url}/company/dashboard/`, {
          headers: {
            "X-COMPANY-USER-AUTH": tk,
          },
        })
        .then((res) => {
          console.log(res.data);
          setState((state) => {
            return { ...state, dashBoardDetails: res.data.dashboard_data };
          });
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  }, []);

  return (
    <>
      {state.dashBoardDetails &&
      state.dashBoardDetails.inactive_services !== 0 ? (
        <section className="dashboard-counts no-padding-bottom">
          <div className="container-fluid">
            <div className="row bg-white has-shadow">
              {/* Item */}
              <div className="col-xl-3 col-sm-6">
                {state.dashBoardDetails && (
                  <div className="item d-flex align-items-center">
                    <div className="icon bg-violet">
                      <i className="icon-grid" />
                    </div>
                    <div className="title">
                      <span>
                        Active
                        <br />
                        Products
                      </span>
                      <div className="progress">
                        <div
                          role="progressbar"
                          style={{ width: "100%", height: "4px" }}
                          aria-valuenow={90}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          className="progress-bar bg-violet"
                        />
                      </div>
                    </div>
                    <div className="number">
                      <strong>{state.dashBoardDetails.total_products}</strong>
                    </div>
                  </div>
                )}
              </div>
              {/* Item */}

              {/* Item */}
              <div className="col-xl-3 col-sm-6">
                {state.dashBoardDetails && (
                  <div className="item d-flex align-items-center">
                    <div className="icon bg-green">
                      <i className="icon-bill" />
                    </div>
                    <div className="title">
                      <span>
                        Active
                        <br />
                        Services
                      </span>
                      <div className="progress">
                        <div
                          role="progressbar"
                          style={{ width: "100%", height: "4px" }}
                          aria-valuenow={40}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          className="progress-bar bg-green"
                        />
                      </div>
                    </div>
                    <div className="number">
                      <strong>{state.dashBoardDetails.active_services}</strong>
                    </div>
                  </div>
                )}
              </div>
              {/* Item */}
              <div className="col-xl-3 col-sm-6">
                {state.dashBoardDetails && (
                  <div className="item d-flex align-items-center">
                    <div className="icon bg-orange">
                      <i className="icon-check" />
                    </div>
                    <div className="title">
                      <span>
                        Retired
                        <br />
                        Products
                      </span>
                      <div className="progress">
                        <div
                          role="progressbar"
                          style={{ width: "100%", height: "4px" }}
                          aria-valuenow={
                            state.dashBoardDetails.inactive_products
                          }
                          aria-valuemin={0}
                          aria-valuemax={100}
                          className="progress-bar bg-orange"
                        />
                      </div>
                    </div>
                    <div className="number">
                      <strong>
                        {state.dashBoardDetails.inactive_products}
                      </strong>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-xl-3 col-sm-6">
                {state.dashBoardDetails && (
                  <div className="item d-flex align-items-center">
                    <div className="icon bg-blue">
                      <i className="icon-presentation" />
                    </div>
                    <div className="title">
                      <span>
                        Retired
                        <br />
                        Services
                      </span>
                      <div className="progress">
                        <div
                          role="progressbar"
                          style={{ width: "100%", height: "4px" }}
                          aria-valuenow={
                            state.dashBoardDetails.inactive_services
                          }
                          aria-valuemin={0}
                          aria-valuemax={100}
                          className="progress-bar bg-blue"
                        />
                      </div>
                    </div>
                    <div className="number">
                      <strong>
                        {state.dashBoardDetails.inactive_services}
                      </strong>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="dashboard-counts no-padding-bottom">
          <div className="container-fluid">
            <div className="row bg-white has-shadow">
              {/* Item */}
              <div className="col-xl-4 col-sm-6">
                {state.dashBoardDetails && (
                  <div className="item d-flex align-items-center">
                    <div className="icon bg-violet">
                      <i className="icon-grid" />
                    </div>
                    <div className="title">
                      <span>
                        Total
                        <br />
                        Products
                      </span>
                      <div className="progress">
                        <div
                          role="progressbar"
                          style={{ width: "25%", height: "4px" }}
                          aria-valuenow={90}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          className="progress-bar bg-violet"
                        />
                      </div>
                    </div>
                    <div className="number">
                      <strong>{state.dashBoardDetails.total_products}</strong>
                    </div>
                  </div>
                )}
              </div>
              {/* Item */}

              {/* Item */}
              <div className="col-xl-4 col-sm-6">
                {state.dashBoardDetails && (
                  <div className="item d-flex align-items-center">
                    <div className="icon bg-green">
                      <i className="icon-bill" />
                    </div>
                    <div className="title">
                      <span>
                        Active
                        <br />
                        Posts
                      </span>
                      <div className="progress">
                        <div
                          role="progressbar"
                          style={{ width: "40%", height: "4px" }}
                          aria-valuenow={40}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          className="progress-bar bg-green"
                        />
                      </div>
                    </div>
                    <div className="number">
                      <strong>{state.dashBoardDetails.active_products}</strong>
                    </div>
                  </div>
                )}
              </div>
              {/* Item */}
              <div className="col-xl-4 col-sm-6">
                {state.dashBoardDetails && (
                  <div className="item d-flex align-items-center">
                    <div className="icon bg-orange">
                      <i className="icon-check" />
                    </div>
                    <div className="title">
                      <span>
                        Retired
                        <br />
                        Posts
                      </span>
                      <div className="progress">
                        <div
                          role="progressbar"
                          style={{ width: "50%", height: "4px" }}
                          aria-valuenow={
                            state.dashBoardDetails.inactive_products
                          }
                          aria-valuemin={0}
                          aria-valuemax={100}
                          className="progress-bar bg-orange"
                        />
                      </div>
                    </div>
                    <div className="number">
                      <strong>
                        {state.dashBoardDetails.inactive_products}
                      </strong>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Analytics;
