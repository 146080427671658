import React, { useEffect, useState } from "react";
import axios from "axios";
import Footer from "../Views/Footer";
import Header from "../Views/Header";

const Profile = () => {
  const [state, setState] = useState({
    companyDetails: null,
  });
  const [payment, setPayment] = useState({
    paymentDetails: null,
  });
  const [token, setToken] = useState(null);

  useEffect(() => {
    // get company details
    const tk = sessionStorage.getItem("token");
    setToken(tk);

    if (token) {
      axios
        .get(
          `${process.env.REACT_APP_Base_url}/company/view_company_details/`,
          {
            headers: {
              "X-COMPANY-USER-AUTH": token,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          setState((state) => {
            return { ...state, companyDetails: res.data.company_details };
          });
        });

      axios
        .get(
          `${process.env.REACT_APP_Base_url}/payment/payment_account_details/`,
          {
            headers: {
              "X-COMPANY-USER-AUTH": token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          setPayment((payment) => {
            return {
              ...payment,
              paymentDetails: response.data.payment_account_details,
            };
          });
        });
    }
  }, [token]);

  return (
    <div className="page">
      {/* Main Navbar*/}
      <Header />

      <div className="page-content d-flex align-items-stretch">
        {/* Side Navbar */}
        <nav className="side-navbar">
          {/* Sidebar Header*/}
          <div className="sidebar-header d-flex align-items-center">
            <div className="title">
              {state.companyDetails && (
                <h4 className="h4">{state.companyDetails.company_name}</h4>
              )}
            </div>
          </div>
          {/* Sidebar Navigation Menus*/}
          <span className="heading">Main</span>
          <ul className="list-unstyled">
            <li>
              <a href="/dashboard">
                {" "}
                <img
                  src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/34/000000/external-dashboard-agile-flaticons-lineal-color-flat-icons.png"
                  alt="Dashboard"
                  className="px-2"
                />{" "}
                Dashboard{" "}
              </a>
            </li>
            <li className="active">
              <a href="/profile">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/user-male-circle.png"
                  alt="Profile"
                  className="px-2"
                />{" "}
                Profile{" "}
              </a>
            </li>
            {/* <Sidebar /> */}
            <li>
              <a href="/notifications">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/apple-mail.png"
                  alt="Notification"
                  className="px-2"
                />{" "}
                Notification{" "}
              </a>
            </li>
            <li>
              <a href="/update_profile">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/edit-user-male.png"
                  alt="update"
                  className="px-2"
                />{" "}
                Update Details{" "}
              </a>
            </li>
            <li>
              <a href="/account_details">
                {" "}
                <img
                  src="https://img.icons8.com/dotty/34/000000/money-bag-euro.png"
                  alt="Payment Details"
                  className="px-2"
                />{" "}
                Payment Details{" "}
              </a>
            </li>
            <li>
              <a href="/change_password">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/settings--v2.png"
                  alt="Settings"
                  className="px-2"
                />{" "}
                Settings{" "}
              </a>
            </li>
          </ul>
        </nav>
        <div className="content-inner">
          {/* Page Header*/}
          <header className="page-header">
            <div className="container-fluid">
              <h2 className="no-margin-bottom">Profile Dashboard</h2>
            </div>
          </header>
          {/* Client Section*/}
          <section className="client no-padding-top">
            <div className="container-fluid pt-3">
              <div className="row">
                {/* Client Profile */}
                <div className="col-lg-8">
                  <div className="client card">
                    <div className="card-close">
                      <div className="dropdown">
                        <button
                          type="button"
                          id="closeCard2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="dropdown-toggle">
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        <div
                          aria-labelledby="closeCard2"
                          className="dropdown-menu dropdown-menu-right has-shadow">
                          <a href="#/" className="dropdown-item remove">
                            {" "}
                            <i className="fa fa-times" />
                            Close
                          </a>
                          <a href="#/" className="dropdown-item edit">
                            {" "}
                            <i className="fa fa-gear" />
                            Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card-body text-center">
                      {state.companyDetails && (
                        <div className="client-avatar">
                          <img
                            src={state.companyDetails.company_logo}
                            alt="company_logo"
                            className="imago"
                          />
                        </div>
                      )}

                      <div className="client-title py-2">
                        {state.companyDetails && (
                          <h3>{state.companyDetails.company_name}</h3>
                        )}
                      </div>
                      <div className="client-info"></div>
                      {state.companyDetails && (
                        <div className="client-social d-flex justify-content-between">
                          <a
                            href={state.companyDetails.social_media.facebook}
                            target="_blank"
                            rel="noreferrer">
                            <i className="fa fa-facebook" />
                          </a>
                          <a
                            href={state.companyDetails.social_media.twitter}
                            target="_blank"
                            rel="noreferrer">
                            <i className="fa fa-twitter" />
                          </a>
                          <a
                            href={state.companyDetails.social_media.instagram}
                            target="_blank"
                            rel="noreferrer">
                            <i className="fa fa-instagram" />
                          </a>
                          {/* <a href={`https://wa.me/+233${state.companyDetails.phone_number.substring(1,10)}`} target="_blank" rel="noreferrer"><i className="fa fa-whatsapp" /></a> */}
                          <a
                            href={state.companyDetails.website}
                            target="_blank"
                            rel="noreferrer">
                            <i className="fa fa-globe" />
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="work-amount card">
                    <div className="card-close">
                      <div className="dropdown">
                        <button
                          type="button"
                          id="closeCard1"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="dropdown-toggle">
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        <div
                          aria-labelledby="closeCard1"
                          className="dropdown-menu dropdown-menu-right has-shadow">
                          <a href="#/" className="dropdown-item remove">
                            {" "}
                            <i className="fa fa-times" />
                            Close
                          </a>
                          <a href="#/" className="dropdown-item edit">
                            {" "}
                            <i className="fa fa-gear" />
                            Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <h3 className="pb-3">Registration Details</h3>
                      <>
                        {state.companyDetails && (
                          <div className="text p-2">
                            <strong>Subscription :</strong>
                            <span className="pl-3">
                              {" "}
                              {state.companyDetails.account_type}
                            </span>
                          </div>
                        )}
                      </>
                      <>
                        {state.companyDetails && (
                          <div className="text p-2">
                            <strong>Status :</strong>
                            <span className="pl-3">
                              {" "}
                              {state.companyDetails.status}
                            </span>
                          </div>
                        )}
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Feeds Section*/}
          <section className="feeds no-padding-top">
            <div className="container-fluid">
              <div className="row">
                {/* Check List */}
                <div className="col-lg-12">
                  <div className="checklist card">
                    <div className="card-close">
                      <div className="dropdown">
                        <button
                          type="button"
                          id="closeCard5"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="dropdown-toggle">
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        <div
                          aria-labelledby="closeCard5"
                          className="dropdown-menu dropdown-menu-right has-shadow">
                          <a href="#/" className="dropdown-item remove">
                            {" "}
                            <i className="fa fa-times" />
                            Close
                          </a>
                          <a href="#/" className="dropdown-item edit">
                            {" "}
                            <i className="fa fa-gear" />
                            Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card-header d-flex align-items-center">
                      <h2 className="h3">Account Details</h2>
                    </div>
                    <div className="card-body">
                      <div className="form-horizontal">
                        <div className="form-group row">
                          <label className="col-sm-3 form-control-label">
                            Corporate Name
                          </label>
                          {state.companyDetails && (
                            <div className="col-sm-9">
                              <h6>{state.companyDetails.company_name}</h6>
                            </div>
                          )}
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-3 form-control-label">
                            Address
                          </label>
                          {state.companyDetails && (
                            <div className="col-sm-9">
                              <h6>{state.companyDetails.address}</h6>
                            </div>
                          )}
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-3 form-control-label">
                            Email Address
                          </label>
                          {state.companyDetails && (
                            <div className="col-sm-9">
                              <h6>{state.companyDetails.email}</h6>
                            </div>
                          )}
                        </div>

                        <div className="form-group row">
                          <label className="col-sm-3 form-control-label">
                            GPS Location Code
                          </label>
                          {state.companyDetails && (
                            <div className="col-sm-9">
                              <h6>{state.companyDetails.gps}</h6>
                            </div>
                          )}
                        </div>

                        <div className="form-group row">
                          <label className="col-sm-3 form-control-label">
                            WhatsApp Number
                          </label>
                          {state.companyDetails && (
                            <div className="col-sm-9">
                              <h6>{state.companyDetails.phone_number}</h6>
                            </div>
                          )}
                        </div>

                        {/* <div className="form-group row">
                                <label className="col-sm-3 form-control-label">Momo Number</label>
                                {state.companyDetails && 
                                  <div className="col-sm-9">
                                    <h6>{state.companyDetails.momo_number}</h6>
                                </div>
                                }
                              </div> */}

                        {/* <div className="form-group row">
                                <label className="col-sm-3 form-control-label">Momo Account Name</label>
                                {state.companyDetails && 
                                  <div className="col-sm-9">
                                    <h6>{state.companyDetails.momo_acct_name}</h6>
                                </div>
                                }
                              </div> */}

                        <div className="form-group row">
                          <label className="col-sm-3 form-control-label">
                            Location
                          </label>
                          {state.companyDetails && (
                            <div className="col-sm-9">
                              <h6>{state.companyDetails.location}</h6>
                            </div>
                          )}
                        </div>

                        <div className="form-group row">
                          <label className="col-sm-3 form-control-label">
                            City
                          </label>
                          {state.companyDetails && (
                            <div className="col-sm-9">
                              <h6>{state.companyDetails.city}</h6>
                            </div>
                          )}
                        </div>

                        <div className="form-group row">
                          <label className="col-sm-3 form-control-label">
                            Background
                          </label>
                          {state.companyDetails && (
                            <div className="col-sm-9">
                              <h6>{state.companyDetails.background}</h6>
                            </div>
                          )}
                        </div>

                        <div className="line" />
                        <div className="form-group row">
                          <label className="col-sm-3 form-control-label">
                            Industry
                          </label>
                          {state.companyDetails && (
                            <div className="col-sm-9">
                              <h6>
                                {state.companyDetails.industry.industry_name}
                              </h6>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <section className="container-fluid form-group">
                    {state.companyDetails &&
                      <div>
                        <div dangerouslySetInnerHTML={{ __html: `${state.companyDetails.google_map_location}`}} />
                      </div>
                    }
                  </section> */}

            {/* Page Footer*/}
            <Footer />
          </section>

          <section className="feeds no-padding-top">
            <div className="container-fluid">
              <div className="row">
                {/* Check List */}
                <div className="col-lg-12">
                  <div className="checklist card">
                    <div className="card-close">
                      <div className="dropdown">
                        <button
                          type="button"
                          id="closeCard5"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="dropdown-toggle">
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        <div
                          aria-labelledby="closeCard5"
                          className="dropdown-menu dropdown-menu-right has-shadow">
                          <a href="#/" className="dropdown-item remove">
                            {" "}
                            <i className="fa fa-times" />
                            Close
                          </a>
                          <a href="#/" className="dropdown-item edit">
                            {" "}
                            <i className="fa fa-gear" />
                            Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card-header d-flex align-items-center">
                      <h2 className="h3">Payment Details</h2>
                    </div>
                    <div className="card-body">
                      <div className="form-horizontal">
                        <div className="form-group row">
                          <label className="col-sm-3 form-control-label">
                            Account Owner Name
                          </label>
                          {payment.paymentDetails && (
                            <div className="col-sm-9">
                              <h6>{payment.paymentDetails.acct_owner}</h6>
                            </div>
                          )}
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-3 form-control-label">
                            Network{" "}
                          </label>
                          {payment.paymentDetails && (
                            <div className="col-sm-9">
                              <h6>{payment.paymentDetails.account_code}</h6>
                            </div>
                          )}
                        </div>

                        {/* <div className="form-group row">
                                <label className="col-sm-3 form-control-label">Branch Name</label>
                                {state.companyDetails && 
                                  <div className="col-sm-9">
                                    <h6>{state.companyDetails.gps}</h6>
                                </div>
                                }
                              </div> */}

                        {/* <div className="form-group row">
                                <label className="col-sm-3 form-control-label">Swift Code</label>
                                {state.companyDetails && 
                                  <div className="col-sm-9">
                                    <h6>{state.companyDetails.phone_number}</h6>
                                </div>
                                }
                              </div> */}

                        <div className="form-group row">
                          <label className="col-sm-3 form-control-label">
                            Momo Number
                          </label>
                          {payment.paymentDetails && (
                            <div className="col-sm-9">
                              <h6>{payment.paymentDetails.account_number}</h6>
                            </div>
                          )}
                        </div>

                        <div className="form-group row">
                          <label className="col-sm-3 form-control-label">
                            Momo Account Name
                          </label>
                          {payment.paymentDetails && (
                            <div className="col-sm-9">
                              <h6>{payment.paymentDetails.account_name}</h6>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <section className="container-fluid form-group">
                    {state.companyDetails &&
                      <div>
                        <div dangerouslySetInnerHTML={{ __html: `${state.companyDetails.google_map_location}`}} />
                      </div>
                    }
                  </section> */}

            {/* Page Footer*/}
            <Footer />
          </section>
        </div>
      </div>
    </div>
  );
};

export default Profile;
