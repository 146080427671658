import React from "react";
import er from "../../Components/img/images.png";

const ErrorPage = () => {
  return (
    <div className="container-fluid py-5">
      <h1 className="text-center py-5">OOPS SORRY !!!!</h1>
      <img src={er} className="rounded mx-auto d-block" alt="error page" />
    </div>
  );
};

export default ErrorPage;
