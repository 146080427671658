import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Views/Footer";
import Header from "../../Views/Header";
import axios from "axios";
// import Sidebar from '../../Pages/Sidebar';

const RetiredServices = () => {
  const [token, setToken] = useState(null);
  const [retired, setRetired] = useState([]);

  useEffect(() => {
    setToken(sessionStorage.getItem("token"));

    if (token) {
      axios
        .get(`${process.env.REACT_APP_Base_url}/product/retired_product/`, {
          headers: {
            "X-COMPANY-USER-AUTH": token,
          },
        })
        .then((res) => {
          console.log(res.data);
          setRetired(res.data.product_post);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [token]);

  return (
    <div className="page">
      {/* Main Navbar*/}
      <Header />
      <div className="page-content d-flex align-items-stretch">
        {/* Side Navbar */}
        <nav className="side-navbar">
          {/* Sidebar Navigation Menus*/}
          <span className="heading">Main</span>
          <ul className="list-unstyled">
            <li>
              <a href="/dashboard">
                {" "}
                <img
                  src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/34/000000/external-dashboard-agile-flaticons-lineal-color-flat-icons.png"
                  alt="Dashboard"
                  className="px-2"
                />{" "}
                Dashboard{" "}
              </a>
            </li>
            <li>
              <a href="/profile">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/user-male-circle.png"
                  alt="Profile"
                  className="px-2"
                />{" "}
                Profile{" "}
              </a>
            </li>
            {/* <Sidebar /> */}
            <li>
              <a href="/notifications">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/apple-mail.png"
                  alt="Notification"
                  className="px-2"
                />{" "}
                Notification{" "}
              </a>
            </li>
            <li>
              <a href="/update_profile">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/edit-user-male.png"
                  alt="update"
                  className="px-2"
                />{" "}
                Update Details{" "}
              </a>
            </li>
            <li>
              <a href="/account_details">
                {" "}
                <img
                  src="https://img.icons8.com/dotty/34/000000/money-bag-euro.png"
                  alt="Payment Details"
                  className="px-2"
                />{" "}
                Payment Details{" "}
              </a>
            </li>
            <li>
              <a href="/change_password">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/settings--v2.png"
                  alt="Settings"
                  className="px-2"
                />{" "}
                Settings{" "}
              </a>
            </li>
          </ul>
        </nav>
        <div className="content-inner">
          {/* Page Header*/}
          <header className="page-header">
            <div className="container-fluid">
              <h2 className="no-margin-bottom">Retired Dashboard</h2>
            </div>
          </header>
          {/* TABLES */}
          <section className="tables">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-close">
                      <div className="dropdown">
                        <button
                          type="button"
                          id="closeCard3"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="dropdown-toggle">
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        <div
                          aria-labelledby="closeCard3"
                          className="dropdown-menu dropdown-menu-right has-shadow">
                          <Link to="#" className="dropdown-item remove">
                            {" "}
                            <i className="fa fa-times" />
                            Close
                          </Link>
                          <Link to="#" className="dropdown-item edit">
                            {" "}
                            <i className="fa fa-gear" />
                            Edit
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="card-header d-flex align-items-center">
                      <h3 className="h4">Retired Service Post </h3>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-striped table-hover">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Product Name</th>
                              <th>Product Category</th>
                              <th>Reference Number</th>
                            </tr>
                          </thead>
                          {retired.length !== 0 &&
                          retired.length !== undefined ? (
                            <tbody>
                              {retired &&
                                retired.map((item) => (
                                  <tr key={item.product_uuid}>
                                    <th scope="row">#</th>
                                    <td>{item.product_name}</td>
                                    <td>
                                      {item.category.product_category_name}
                                    </td>
                                    <td>{item.reference_number}</td>
                                  </tr>
                                ))}
                            </tbody>
                          ) : (
                            <tbody>
                              <tr>
                                <th></th>
                                <td></td>
                                <td className="py-3">No Records Found</td>
                                <td></td>
                              </tr>
                            </tbody>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Page Footer*/}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default RetiredServices;
