import React from "react";
import UpdateProfile from "../../Components/Update Profile/UpdateProfile";

const UpdateProfilePage = () => {
  return (
    <>
      <UpdateProfile />
    </>
  );
};

export default UpdateProfilePage;
