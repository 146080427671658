import { useLocation, useNavigate } from "react-router";
import queryString from "query-string";
import React, { useState } from "react";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { email, company_id, token } = queryString.parse(search);
  console.log(search);
  const [new_password, setNewPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");

  const passheader = <h6>Pick a password</h6>;
  const passfooter = (
    <React.Fragment>
      <Divider />
      <p className="p-mt-2">Suggestions</p>
      <ul className="p-pl-2 p-ml-2 p-mt-0" style={{ lineHeight: "1.5" }}>
        <li>At least one lowercase</li>
        <li>At least one uppercase</li>
        <li>At least one numeric</li>
        <li>Minimum 8 characters</li>
      </ul>
    </React.Fragment>
  );

  const settingsHandler = (e) => {
    e.preventDefault();
    if (new_password !== confirm_password) {
      toast.error("Passwords don't match");
    } else {
      if (company_id && token) {
        axios
          .post(
            `${process.env.REACT_APP_Base_url}/company/complete_password_reset/`,
            {
              company_uuid: company_id,
              token,
              new_password,
              confirm_password,
            }
          )
          .then((res) => {
            console.log(res);
            console.log("Redirect successful");
            toast.success(res.data.message);
            setTimeout(() => {
              navigate("/");
            }, 2000);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  return (
    <>
      <ToastContainer />

      <div className="container py-5" style={{ height: "100vh" }}>
        <h1 className="text-center">Reset Password Page</h1>
        <hr />
        <p className="text-center py-3">
          Your account with email address <b>{email} </b> password has been
          revetted successfully.
        </p>
        <p className="text-center py-3">Please set a new Password</p>
        {/* Forms Section*/}
        <section className="forms">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header d-flex align-items-center"></div>

                  <div className="card-body">
                    <form
                      className="form-horizontal"
                      onSubmit={settingsHandler}>
                      <div className="card">
                        <div className="card-body">
                          <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col">
                              <h5>New Password</h5>
                              <Password
                                required
                                value={new_password}
                                onChange={(e) => setNewPassword(e.target.value)}
                                toggleMask
                                header={passheader}
                                footer={passfooter}
                              />
                            </div>
                            <br />
                            <div className="p-field p-col">
                              <h5>Confirm Password</h5>
                              <Password
                                required
                                value={confirm_password}
                                onChange={(e) =>
                                  setConfirmPassword(e.target.value)
                                }
                                toggleMask
                              />
                            </div>
                            <br />
                            <div className="p-field p-col">
                              <Button label="Submit" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Page Footer*/}
      </div>
    </>
  );
};

export default ResetPassword;
