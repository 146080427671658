import React from "react";
import BarChartDemo from "../Charts/BarChartDemo";

const Graph = () => {
  return (
    <>
      <section className="charts">
        <div className="container-fluid">
          <div className="row">
            {/* Line Charts*/}
            <div className="col-lg-12">
              <div className="line-chart-example card">
                <div className="card-close">
                  <div className="dropdown">
                    <button
                      type="button"
                      id="closeCard1"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="dropdown-toggle">
                      <i className="fa fa-ellipsis-v" />
                    </button>
                    <div
                      aria-labelledby="closeCard1"
                      className="dropdown-menu dropdown-menu-right has-shadow">
                      <a href="#/" className="dropdown-item remove">
                        {" "}
                        <i className="fa fa-times" />
                        Close
                      </a>
                      <a href="#/" className="dropdown-item edit">
                        {" "}
                        <i className="fa fa-gear" />
                        Edit
                      </a>
                    </div>
                  </div>
                </div>
                <div className="card-header d-flex align-items-center">
                  <h3 className="h4">Ads Posts Analytics</h3>
                </div>
                <div className="card-body">
                  <BarChartDemo />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Graph;
