import React from "react";
import Register from "../../Components/Registration/Register";

const RegistrationPage = () => {
  return (
    <>
      <Register />
    </>
  );
};

export default RegistrationPage;
