import React from "react";
import Home from "../../Components/Dashboard/Home";

const Dashboard = () => {
  return (
    <>
      <Home />
    </>
  );
};

export default Dashboard;
