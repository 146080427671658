import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const ProtectedRoute = () => {
  const token = sessionStorage.getItem("token");
  const accountType = sessionStorage.getItem("accountType");
  const location = useLocation();

  const { pathname } = location;

  if (accountType === "Pay As You Go") {
    if (pathname.includes("Payment") || pathname.includes("Services")) {
      return <Navigate to="*" replace={true} />;
    }
  }

  return token ? (
    <Outlet />
  ) : (
    <Navigate to="/" replace={true} state={{ from: location }} />
  );
};

export default ProtectedRoute;
