import React from "react";
import ChangePassword from "../../Components/Password/ChangePassword";

const ChangePasswordPage = () => {
  return (
    <>
      <ChangePassword />
    </>
  );
};

export default ChangePasswordPage;
