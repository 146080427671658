import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const RecentActivities = () => {
  const [recent, setRecent] = useState([]);
  const [token, setToken] = useState(null);

  useEffect(() => {
    // get token details
    const tk = sessionStorage.getItem("token");
    setToken(tk);

    if (token) {
      axios
        .get(`${process.env.REACT_APP_Base_url}/company/get_company_reviews/`, {
          headers: {
            "X-COMPANY-USER-AUTH": token,
          },
        })
        .then((res) => {
          // console.log(res.data);
          setRecent(res.data.review_rating.slice(0, 4));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [token]);

  return (
    <div>
      <section className="updates no-padding-top">
        <div className="container-fluid">
          <div className="row">
            {/* Recent Activities */}
            <div className="col-lg-12">
              <div className="recent-activities card">
                <div className="card-close">
                  <div className="dropdown">
                    <button
                      type="button"
                      id="closeCard8"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="dropdown-toggle">
                      <i className="fa fa-ellipsis-v" />
                    </button>
                    <div
                      aria-labelledby="closeCard8"
                      className="dropdown-menu dropdown-menu-right has-shadow">
                      <a href="#/" className="dropdown-item remove">
                        {" "}
                        <i className="fa fa-times" />
                        Close
                      </a>
                      <a href="#/" className="dropdown-item edit">
                        {" "}
                        <i className="fa fa-gear" />
                        Edit
                      </a>
                    </div>
                  </div>
                </div>
                {recent.length > 0 ? (
                  <>
                    <div className="card-header">
                      <h3 className="h4">Recent Activities</h3>
                    </div>
                    <div className="card-body no-padding">
                      {recent &&
                        recent.map((activity) => (
                          <div className="item" key={activity.review_id}>
                            <div className="row">
                              <div className="col-2 date-holder text-right">
                                <div className="icon">
                                  <i className="icon-clock" />
                                </div>
                                <div className="date">
                                  <br />
                                  <span className="text-info">
                                    {activity.date_created}
                                  </span>
                                </div>
                              </div>
                              <div className="col-8 content">
                                <h4>
                                  {activity.shopper.first_name}{" "}
                                  {activity.shopper.last_name}
                                </h4>
                                <h5>{activity.shopper.phone}</h5>
                                <div>
                                  {/* <a href="/NotificationDetails" className="btn btn-xs btn-secondary">More</a> */}
                                  <Link
                                    to={{
                                      pathname: `/notification_details`,
                                      state: { id: activity.review_id },
                                    }}
                                    className="btn btn-xs btn-secondary">
                                    Read more ...
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </>
                ) : (
                  <div className="card-body no-padding">
                    <div className="item">
                      <div className="row">
                        <div className="col-2 date-holder text-right">
                          <div className="icon">
                            <i className="icon-clock" />
                          </div>
                          <div className="date">
                            <br />
                            <span className="text-info">
                              No Recent Activity
                            </span>
                          </div>
                        </div>
                        <div className="col-8 content">
                          <h4>No Recent Activity</h4>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RecentActivities;
