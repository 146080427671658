import React, { useEffect, useState, useRef } from "react";
import Footer from "../../Views/Footer";
import Header from "../../Views/Header";
import axios from "axios";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";

const ActiveProducts = () => {
  const toast = useRef(null);
  const [token, setToken] = useState(null);
  const [active, setActive] = useState([]);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);

    if (token) {
      axios
        .get(`${process.env.REACT_APP_Base_url}/product/list_active_product/`, {
          headers: {
            "X-COMPANY-USER-AUTH": token,
          },
        })
        .then((res) => {
          console.log(res.data);
          setActive(res.data.active_post || res.data.product_post);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [token]);

  const accept = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/product/add_trend/`,
        {
          product_id: id,
        },
        {
          headers: {
            "X-COMPANY-USER-AUTH": token,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.current.show({
          severity: "info",
          summary: "Confirmed",
          detail: `${res.data.message} `,
          life: 3000,
        });
        setTimeout(() => {
          window.location.reload(true);
        }, 5000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const reject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    });
  };

  const confirm1 = (id) => {
    confirmDialog({
      message: "Are you sure you want to proceed?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        accept(id);
      },
      reject,
    });
  };

  const handleBoost = (id) => {
    console.log(id);
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/product/add_trend/`,
        {
          product_id: id,
        },
        {
          headers: {
            "X-COMPANY-USER-AUTH": token,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        alert(res.data.message);
        window.location.reload(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />
      <div className="page">
        {/* Main Navbar*/}
        <Header />

        <div className="page-content d-flex align-items-stretch">
          {/* Side Navbar */}
          <nav className="side-navbar">
            {/* Sidebar Header*/}
            <span className="heading py-3">Main</span>
            {/* Sidebar Navigation Menus*/}
            <ul className="list-unstyled">
              <li>
                <a href="/dashboard">
                  {" "}
                  <img
                    src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/34/000000/external-dashboard-agile-flaticons-lineal-color-flat-icons.png"
                    alt="Dashboard"
                    className="px-2"
                  />{" "}
                  Dashboard{" "}
                </a>
              </li>
              <li>
                <a href="/profile">
                  {" "}
                  <img
                    src="https://img.icons8.com/ios/34/000000/user-male-circle.png"
                    alt="Profile"
                    className="px-2"
                  />{" "}
                  Profile{" "}
                </a>
              </li>
              {/* <Sidebar /> */}
              <li>
                <a href="/notifications">
                  {" "}
                  <img
                    src="https://img.icons8.com/ios/34/000000/apple-mail.png"
                    alt="Notification"
                    className="px-2"
                  />{" "}
                  Notification{" "}
                </a>
              </li>
              <li>
                <a href="/update_profile">
                  {" "}
                  <img
                    src="https://img.icons8.com/ios/34/000000/edit-user-male.png"
                    alt="update"
                    className="px-2"
                  />{" "}
                  Update Details{" "}
                </a>
              </li>
              <li>
                <a href="/account_details">
                  {" "}
                  <img
                    src="https://img.icons8.com/dotty/34/000000/money-bag-euro.png"
                    alt="Payment Details"
                    className="px-2"
                  />{" "}
                  Payment Details{" "}
                </a>
              </li>
              <li>
                <a href="/change_password">
                  {" "}
                  <img
                    src="https://img.icons8.com/ios/34/000000/settings--v2.png"
                    alt="Settings"
                    className="px-2"
                  />{" "}
                  Settings{" "}
                </a>
              </li>
            </ul>
          </nav>
          <div className="content-inner">
            {/* Page Header*/}
            <header className="page-header">
              <div className="container-fluid">
                <h2 className="no-margin-bottom">Active Dashboard</h2>
              </div>
            </header>
            {/* TABLES */}
            <section className="tables">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-close">
                        <div className="dropdown">
                          <button
                            type="button"
                            id="closeCard3"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            className="dropdown-toggle">
                            <i className="fa fa-ellipsis-v" />
                          </button>
                          <div
                            aria-labelledby="closeCard3"
                            className="dropdown-menu dropdown-menu-right has-shadow">
                            <a href="#/" className="dropdown-item remove">
                              {" "}
                              <i className="fa fa-times" />
                              Close
                            </a>
                            <a href="#/" className="dropdown-item edit">
                              {" "}
                              <i className="fa fa-gear" />
                              Edit
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="card-header d-flex align-items-center">
                        <h3 className="h4">Active Product Post </h3>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table table-striped table-hover">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Product Name</th>
                                <th>Product Category</th>
                                <th>Reference Number</th>
                                <th>Boost</th>
                              </tr>
                            </thead>

                            {active.length !== 0 &&
                            active.length !== undefined ? (
                              <tbody>
                                {active &&
                                  active.map((product) => (
                                    <tr key={product.product_uuid}>
                                      <th scope="row">#</th>
                                      <td>{product.product_name}</td>
                                      <td>
                                        {product.category.product_category_name}
                                      </td>
                                      <td>{product.reference_number}</td>
                                      <td>
                                        <button
                                          onClick={() =>
                                            confirm1(product.product_uuid)
                                          }
                                          type="button"
                                          className="btn btn-primary">
                                          Boost
                                        </button>
                                      </td>
                                      {/* <td>
                                        <button
                                          onClick={() =>
                                            confirm1(product.product_uuid)
                                          }
                                          type="button"
                                          className="btn btn-primary"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Boosted items appear on the home page">
                                          Boost
                                        </button>
                                      </td> */}
                                    </tr>
                                  ))}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <th></th>
                                  <td></td>
                                  <td className="py-3">No Records Found</td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Page Footer*/}
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default ActiveProducts;
