import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../../Views/Footer";
import Header from "../../Views/Header";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DraftedServiceEdit = () => {
  const location = useLocation();
  const [token, setToken] = useState(null);
  const [state, setState] = useState(null);
  const [catList, setCatList] = useState([]);
  const [subcat, setSubCat] = useState([]);
  const [category_id, setCategoryId] = useState("");
  const [sub_category_id, setSubCategoryId] = useState("");
  const [region, setRegion] = useState("");
  const [regionList, setRegionList] = useState(null);
  const [id, setId] = useState(null);

  let formData = new FormData();

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);
    if (location.state) {
      setId(location.state.id);
    }
  }, [token, location.state]);

  useEffect(() => {
    // List Services  Category
    axios
      .get(`${process.env.REACT_APP_Base_url}/setup/list_service_category/`)
      .then((res) => {
        console.log(res.data);
        setCatList(res.data.service_category);
      })
      .catch((err) => {
        console.log(err);
      });

    if (token) {
      axios
        .get(`${process.env.REACT_APP_Base_url}/setup/get_regions/`, {
          headers: {
            "X-COMPANY-USER-AUTH": token,
          },
        })
        .then((response) => {
          console.log(response.data);
          setRegionList(response.data.regions);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (id) {
      axios
        .post(
          `${process.env.REACT_APP_Base_url}/product/service_details/`,
          {
            service_id: id,
          },
          {
            headers: {
              "x-user-pass-auth":
                "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          setState(response.data.service_details);
          console.log(response.data.service_details);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [id, token]);

  const getSubCategory = () => {
    // gettingData from product sub_category_name
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup/list_service_sub_category/`,
        {
          service_cat_uuid: category_id,
        }
      )
      .then((resp) => {
        console.log(resp.data);
        setSubCat(resp.data.service_sub_category);
      })
      .catch((erro) => {
        console.error(erro);
      });
  };

  const onFileChange = (e) => {
    setState((state) => {
      return { ...state, attached_image: e.target.files[0] };
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    formData.append("service_id", id);
    formData.append("attached_file", state.attached_image);
    formData.append("service_name", state.service_name);
    formData.append("region_id", region);
    formData.append("ref_number", state.reference_number);
    formData.append("description", state.description);
    formData.append("category_id", category_id);
    formData.append("service_sub_category_id", sub_category_id);

    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    if (category_id !== "" && sub_category_id !== "" && region !== "") {
      axios
        .post(
          `${process.env.REACT_APP_Base_url}/product/edit_service/`,
          formData,
          {
            headers: {
              "X-COMPANY-USER-AUTH": token,
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
            setTimeout(() => {
              window.location.replace("/drafted_services");
            }, 3000);
          } else {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
            setTimeout(() => {
              window.location.replace("/drafted_services");
            }, 3000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.warn("Please fill out all the required fields with the red *");
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="page">
        <Header />

        <div className="page-content d-flex align-items-stretch">
          {/* Side Navbar */}
          <nav className="side-navbar">
            {/* Sidebar Header*/}
            <span className="heading py-3">Main</span>
            <ul className="list-unstyled">
              <li>
                <a href="/dashboard">
                  {" "}
                  <img
                    src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/34/000000/external-dashboard-agile-flaticons-lineal-color-flat-icons.png"
                    alt="Dashboard"
                    className="px-2"
                  />{" "}
                  Dashboard{" "}
                </a>
              </li>
              <li>
                <a href="/profile">
                  {" "}
                  <img
                    src="https://img.icons8.com/ios/34/000000/user-male-circle.png"
                    alt="Profile"
                    className="px-2"
                  />{" "}
                  Profile{" "}
                </a>
              </li>
              {/* <Sidebar /> */}
              <li>
                <a href="/notifications">
                  {" "}
                  <img
                    src="https://img.icons8.com/ios/34/000000/apple-mail.png"
                    alt="Notification"
                    className="px-2"
                  />{" "}
                  Notification{" "}
                </a>
              </li>
              <li>
                <a href="/update_profile">
                  {" "}
                  <img
                    src="https://img.icons8.com/ios/34/000000/edit-user-male.png"
                    alt="update"
                    className="px-2"
                  />{" "}
                  Update Details{" "}
                </a>
              </li>
              <li>
                <a href="/account_details">
                  {" "}
                  <img
                    src="https://img.icons8.com/dotty/34/000000/money-bag-euro.png"
                    alt="Payment Details"
                    className="px-2"
                  />{" "}
                  Payment Details{" "}
                </a>
              </li>
              <li>
                <a href="/change_password">
                  {" "}
                  <img
                    src="https://img.icons8.com/ios/34/000000/settings--v2.png"
                    alt="Settings"
                    className="px-2"
                  />{" "}
                  Settings{" "}
                </a>
              </li>
            </ul>
          </nav>
          <div className="content-inner">
            {/* Page Header*/}
            <header className="page-header">
              <div className="container-fluid">
                <h2 className="no-margin-bottom">Edit Service Dashboard</h2>
              </div>
            </header>
            {/* Updates Section                                                */}
            <section className="updates no-padding-top">
              <div className="container-fluid">
                <div className="row">
                  {/* Daily Feeds */}
                  <div className="col-lg-12 pt-5">
                    <div className="daily-feeds card">
                      <div className="card-close">
                        <div className="dropdown">
                          <button
                            type="button"
                            id="closeCard7"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            className="dropdown-toggle">
                            <i className="fa fa-ellipsis-v" />
                          </button>
                          <div
                            aria-labelledby="closeCard7"
                            className="dropdown-menu dropdown-menu-right has-shadow">
                            <Link to="#" className="dropdown-item remove">
                              {" "}
                              <i className="fa fa-times" />
                              Close
                            </Link>
                            <Link to="#" className="dropdown-item edit">
                              {" "}
                              <i className="fa fa-gear" />
                              Edit
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="card-header">
                        <h3 className="h4">Edit Service Post</h3>
                      </div>
                      <div className="card-body no-padding">
                        {state && (
                          <form
                            className="container py-5"
                            onSubmit={submitHandler}>
                            <label htmlFor="basic-url" className="form-label">
                              Name of Service
                            </label>
                            <div className="input-group mb-3">
                              <span
                                className="input-group-text"
                                id="basic-addon2">
                                Name of Service
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={state.service_name}
                                onChange={(e) =>
                                  setState((state) => {
                                    return {
                                      ...state,
                                      service_name: e.target.value,
                                    };
                                  })
                                }
                              />
                            </div>

                            <label htmlFor="basic-url" className="form-label">
                              Reference #
                            </label>
                            <div className="input-group mb-3">
                              <span
                                className="input-group-text"
                                id="basic-addon1">
                                Reference #
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={state.reference_number}
                                onChange={(e) =>
                                  setState((state) => {
                                    return {
                                      ...state,
                                      service_name: e.target.value,
                                    };
                                  })
                                }
                              />
                            </div>

                            <label htmlFor="basic-url" className="form-label">
                              Description of Service
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">
                                Description of Service
                              </span>
                              <textarea
                                className="form-control"
                                aria-label="With textarea"
                                placeholder={state.description}
                                onChange={(e) =>
                                  setState((state) => {
                                    return {
                                      ...state,
                                      description: e.target.value,
                                    };
                                  })
                                }
                              />
                            </div>
                            <br />
                            <hr />

                            {catList && (
                              <div className="form-group row">
                                <label className="col-sm-3 form-control-label">
                                  Category of Service
                                  <span
                                    className="px-1"
                                    style={{ color: "red", fontSize: "18px" }}>
                                    *
                                  </span>
                                </label>
                                <div className="col-sm-9">
                                  <select
                                    name="account"
                                    required
                                    className="form-control mb-3"
                                    onChange={(e) =>
                                      setCategoryId(e.target.value)
                                    }>
                                    <option>---</option>
                                    {catList.map((cat, key) => {
                                      return (
                                        <option
                                          value={cat.service_category_id}
                                          key={key}>
                                          {cat.service_category_name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                            )}
                            <hr />

                            {subcat && (
                              <div className="form-group row">
                                <label className="col-sm-3 form-control-label">
                                  Sub-Category of Service
                                  <span
                                    className="px-1"
                                    style={{ color: "red", fontSize: "18px" }}>
                                    *
                                  </span>
                                </label>
                                <div className="col-sm-9">
                                  <select
                                    name="account"
                                    required
                                    className="form-control mb-3"
                                    onClick={getSubCategory}
                                    onChange={(e) =>
                                      setSubCategoryId(e.target.value)
                                    }>
                                    <option>---</option>
                                    {subcat.map((cats, key) => {
                                      return (
                                        <option
                                          value={cats.service_sub_category_id}
                                          key={key}>
                                          {cats.sub_category_name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                            )}
                            <hr />

                            {regionList && (
                              <div className="form-group row pt-2">
                                <label className="col-sm-3 form-control-label">
                                  {" "}
                                  Region of Location
                                  <span
                                    className="px-1"
                                    style={{ color: "red", fontSize: "18px" }}>
                                    *
                                  </span>
                                </label>
                                <div className="col-sm-9">
                                  <select
                                    name="region"
                                    required
                                    className="form-control mb-3"
                                    onChange={(e) => setRegion(e.target.value)}>
                                    <option>---</option>
                                    {regionList.map((content, key) => {
                                      return (
                                        <option
                                          value={content.region_id}
                                          key={key}>
                                          {content.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                            )}
                            <hr />

                            <div className="input-group ">
                              <label
                                htmlFor="fileInput"
                                className="col-sm-3 form-control-label">
                                Upload an Image
                                <span
                                  className="px-1"
                                  style={{ color: "red", fontSize: "18px" }}>
                                  *
                                </span>
                              </label>
                              <div className="col-sm-9">
                                <input
                                  id="fileInput"
                                  type="file"
                                  className="form-control-file"
                                  accept="image/*"
                                  onChange={onFileChange}
                                />
                              </div>
                            </div>
                            <hr />

                            <div className="text-center pt-4">
                              <button
                                type="submit"
                                name="submit"
                                value="upload"
                                className="btn btn-primary">
                                send post
                              </button>
                            </div>
                          </form>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Page Footer*/}
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default DraftedServiceEdit;
